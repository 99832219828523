import { useEffect } from "react";

/**
 * Custom hook to conditionally disable scrolling on the <html> element.
 *
 * @param {boolean} disabled - Flag to disable or enable scrolling.
 */
function useDisableScroll(disabled) {
  useEffect(() => {
    const element = document.documentElement; // Target the <html> element
    const originalStyle = element.style.overflow;

    if (disabled) {
      // Disable scrolling
      element.style.height = "100vh";
      element.style.overflow = "hidden";
    } else {
      // Re-enable scrolling
      element.style.height = "initial";
      element.style.overflow = originalStyle || "";
    }

    // Cleanup function to restore the original overflow when the component unmounts or when dependencies change
    return () => {
      element.style.overflow = originalStyle || "";
    };
  }, [disabled]);
}

export default useDisableScroll;
