import styled, { keyframes } from "styled-components";
import { CommonColor } from "../utils/Color";
import { CommonFont } from "../utils/Font";
import { CommonImages } from "../utils/CommonImages";
import { ReviewPageTextTitle } from "./home.style";
import { DeviceSizes } from "./common.style";

const RecruitementFeatureContainerSmall = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-column: span 3;
  grid-row: span 12;
`;
const RecruitmentFeatureCard = styled.div`
  --mouse-x: 0;
  --mouse-y: 0;
  display: flex;
  perspective: 900px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40%;
  box-sizing: border-box;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  /* margin-top: 22px; */
  margin-bottom: 22px;
  &::before,
  & .card-border {
    height: 100%;
    width: 100%;
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: inherit;
    z-index: 2;

    opacity: 0;
    transition: opacity 500ms;
  }
  &::before {
    background: radial-gradient(
      800px circle at var(--mouse-x) var(--mouse-y),
      rgba(255, 255, 255, 0.06),
      transparent 40%
    );
    z-index: 3;
  }
  & .card-border {
    background: radial-gradient(
      400px circle at var(--mouse-x) var(--mouse-y),
      rgba(255, 255, 255, 0.7),
      transparent 40%
    );
    z-index: 1;
  }
  &:hover::before {
    opacity: 1;
  }
  @media ${DeviceSizes.mobile} {
    width: calc(50% - 10px);
    height: 125px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
`;
const RecruitementFeatureTitle = styled.div`
  color: ${CommonColor.grey};
  font-size: ${CommonFont.ms};
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 10px;
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.semiLB};
  }
`;
const RecruitmentFeatureNumber = styled.h4`
  overflow: hidden;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Fahkwang";
  font-size: ${CommonFont.vl};
  font-style: normal;
  font-weight: 700;
  margin: 0;
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.xxl};
    height: 60px;
  }
`;
const RecruitementFeatureContainerBig = styled(
  RecruitementFeatureContainerSmall
)`
  grid-column: span 7;
  & > div:nth-last-child(1) > div {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  & > div:nth-last-child(1) > div:nth-child(2) > div {
    width: max-content;
  }
`;
const RecruitmentFeatureCardBig = styled(RecruitmentFeatureCard)`
  aspect-ratio: unset;
  height: 100%;
  flex-direction: row;
  align-items: center;
  box-sizing: content-box;
`;
const RecruitementFeaturSectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(13, 1fr);
  grid-template-rows: repeat(12, 1fr);
  grid-gap: 20px;
  margin: 7%;
  margin-top: 0;
  margin-bottom: 0;
  & > div:nth-child(1) > div:nth-child(2) {
    height: 60%;
  }
  & > div:nth-child(3) > div:nth-child(1) {
    height: 60%;
  }
  &:hover .card-recruitement .card-border {
    opacity: 1;
  }
  @media ${DeviceSizes.mobile} {
    display: none;
  }
`;
const RecruitementFeatureCardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  @media ${DeviceSizes.mobile} {
    width: 100%;
    align-items: flex-start;
    margin-top: 40px;
  }
`;
const RecruitementFeatureCardTitle = styled.div`
  color: ${CommonColor.white};

  font-size: ${CommonFont.m};
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.xil};
  }
`;
const RecruitementFeatureCardList = styled.div`
  color: ${CommonColor.grey};

  font-size: ${CommonFont.ms};
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.vl};
  }
`;
const RecruitmentFeatureBgWrapper = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const RecruitementFeatureImg = styled(CommonImages.TalentAcquistionDecorator)`
  height: 140px;
`;
const Account1FeatureImg = styled(CommonImages.account.acccount_1)`
  height: 140px;
`;
const Account2FeatureImg = styled(CommonImages.account.acccount_2)`
  height: 140px;
  @media ${DeviceSizes.abovetablet} {
    width: 140px;
  }
`;
const RecruitementExecutionServiceImg = styled(
  CommonImages.RecruitementExecutionServiceImg
)`
  height: 140px;
`;
const ServingClientsContainer = styled.div`
  margin-top: 120px;
  padding-bottom: 30vh;
  @media ${DeviceSizes.mobile} {
    margin-left: 20px;
    margin-top: 100px;
  }
`;
const ServingClientsTitle = styled.div`
  color: ${CommonColor.white};

  font-size: ${CommonFont.l};
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 67.858px */
  letter-spacing: 0.565px;
  text-align: center;
  margin-bottom: 50px;
  @media ${DeviceSizes.mobile} {
    margin-bottom: 40px;
    text-align: left;
    font-size: ${CommonFont.xl};
    margin-right: 40px;
  }
`;

const ServingClientIconContainer = styled.div`
  margin-right: 5px;
  margin-left: 50px;
  color: white;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.15);
  width: 39px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${DeviceSizes.mobile} {
    margin: 0;
  }
`;

const ServingClientText = styled.div`
  color: ${CommonColor.white};

  font-size: ${CommonFont.ms};
  font-style: normal;
  font-weight: 600;
  line-height: 28.495px; /* 106.25% */
  letter-spacing: 0.402px;
  width: max-content;
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.semiLB};
  }
`;

const LinearGradientBg = styled.div`
  filter: blur(50px);
  position: absolute;
  right: 0;
  top: 0;
  height: 100px;
  aspect-ratio: 1/1;
`;
const MovingBgRecruitement = styled.div`
  background-color: white;
  background-size: 50% 50%;
  height: 100px;
  width: 100px;
  filter: blur(40px);
  position: absolute;
  /* transform: scale(0); */
`;

const RecruitementFeatureCardContent = styled(RecruitmentFeatureCard)`
  background-color: rgb(23, 23, 23);
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  box-sizing: border-box;
  border-radius: inherit;
  margin: 1px;
  z-index: 2;
  @media ${DeviceSizes.mobile} {
    padding: 12px;
  }
`;
const RecruitementFeatureCardContentBig = styled(RecruitmentFeatureCardBig)`
  background-color: rgb(23, 23, 23);
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  padding: 2.5vw;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  border-radius: inherit;
  z-index: 2;
  margin: auto;
`;
const RecruitementParaContainer = styled.div`
  margin-top: 25vh;
  width: 70%;
  margin-bottom: 20vh;
  @media ${DeviceSizes.mobile} {
    margin-top: 6vh;
    width: 100%;
    margin-bottom: 10vh;

    margin-left: 0;
  }
`;
const ParaTitle = styled(ReviewPageTextTitle)`
  color: ${CommonColor.white};
  width: 23%;
  display: flex;
  font-size: ${CommonFont.m};
  font-weight: 500;
  @media ${DeviceSizes.mobile} {
    margin-bottom: 20px;
    font-size: ${CommonFont.vl};
    width: max-content;
  }
`;
const ParaText = styled(ReviewPageTextTitle)`
  color: ${CommonColor.white};
  width: 100%;
  width: fit-content;
  font-size: ${CommonFont.semiLarge};
  & div {
    font-weight: 500;
  }
  @media ${DeviceSizes.mobile} {
    width: calc(100% - 20px);
    & > div {
      font-size: ${CommonFont.l};
      font-weight: 400;
    }
  }
`;
const RecruitementFeaturSectionContainerMobile = styled.div`
  display: none;
  @media ${DeviceSizes.mobile} {
    display: block;
  }
`;
const CardImgContainerRecMobile = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const RecruitementMobSvg = styled.img`
  height: 100%;
  aspect-ratio: 1/1;
`;
const carousel = keyframes`
0%{
translate: 0%;
}
100%{
  translate: -50%;
}
`;
const CarouselWrapper = styled.div`
  width: max-content;
  overflow: hidden;
  animation: ${carousel} 20s linear infinite;
  display: flex;
`;
const SliderContainer = styled.div`
  position: absolute;
  display: flex;
`;
const swipe = keyframes`
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-50%);
  }
`;
const Slider = styled.div`
  display: flex;
  animation: ${swipe} 45s linear infinite backwards;
`;
const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 5rem;
`;
export {
  Account1FeatureImg,
  Account2FeatureImg,
  SliderContainer,
  Slider,
  SliderWrapper,
  CarouselWrapper,
  RecruitementMobSvg,
  CardImgContainerRecMobile,
  RecruitementFeaturSectionContainerMobile,
  ParaText,
  ParaTitle,
  RecruitementParaContainer,
  RecruitementFeatureCardContentBig,
  RecruitementFeatureCardContent,
  MovingBgRecruitement,
  RecruitementExecutionServiceImg,
  LinearGradientBg,
  ServingClientText,
  ServingClientIconContainer,
  ServingClientsTitle,
  ServingClientsContainer,
  RecruitementFeatureImg,
  RecruitmentFeatureBgWrapper,
  RecruitementFeatureCardList,
  RecruitementFeatureCardTitle,
  RecruitementFeatureCardContentContainer,
  RecruitementFeaturSectionContainer,
  RecruitementFeatureContainerSmall,
  RecruitmentFeatureCard,
  RecruitementFeatureTitle,
  RecruitmentFeatureNumber,
  RecruitementFeatureContainerBig,
  RecruitmentFeatureCardBig,
};
