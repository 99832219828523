import React, { useEffect, useRef, useState } from "react";
import DropDown from "../../assets/svgs/DropDown";
import PrexelIcon from "../../assets/svgs/PrexelIcon";
import {
  HeaderContainer,
  HeaderContent,
  HeaderContentMobile,
  HeaderTextContainer,
  IconContainer,
  IconContainerNav,
  MenuHeaderBackground,
  MenuIconContainer,
  MenuText,
  MobileNav,
  NavIconFirstContainer,
  NavIconSecond,
  NavIconThirdContainer,
  PrexelIconContainer,
  ServiceMenuContainerMobile,
  ServiceMenuPositioner,
  ServiceMenuText,
  TitleText,
} from "../../style/header.style";
import { Div, Span } from "../../style/home.style";
// import AeroPlane from "../../assets/svgs/AeroPlane";
// import { CommonColor } from "../../utils/Color";
import { Icon } from "@iconify/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useScreenSize from "../../hooks/WindowSize";
import { onChangeNav } from "../../service/Redux/UserStore";
import { FlexContainer } from "../../style/common.style";
import {
  SocialIconContainer,
  SocialIconSingleContainer,
} from "../../style/footer.style";
import { startLoader } from "../../utils/Functions";
import { path } from "../../utils/Path";
import useHover from "../../utils/useHover";
import MagneticHeaderButton from "./MagneticHeaderButton";
gsap.registerPlugin(ScrollTrigger);

export default function Header() {
  const [hoverRef] = useHover();
  const [isOpen, setIsOpen] = useState(false);
  const [isServiceMenuClose, setIsServiceMenuClose] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const parent = useRef(0);
  const nav = useRef(null);
  const fadeRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const width = useScreenSize().width;

  useEffect(() => {
    if (fadeRef.current === null) return;
    const svg = document.querySelector("#header-svg");
    svg.setAttribute("width", fadeRef.current.clientWidth);
    svg.setAttribute("height", fadeRef.current.clientHeight);
    svg.setAttribute(
      "viewBox",
      "0 0" +
        " " +
        fadeRef.current.clientWidth +
        " " +
        fadeRef.current.clientHeight
    );
  }, [fadeRef]);
  useEffect(() => {
    if (anchorEl === null) return;
    // Function to handle click outside
    const preventScroll = (event) => {
      if (anchorEl !== null) {
        // Clicked outside the wrapper
        setAnchorEl(null);
      }
    };
    // Attach the event listener
    document.addEventListener("scroll", preventScroll);

    // Detach the event listener on component unmount
    return () => {
      document.removeEventListener("scroll", preventScroll);
    };
  }, [anchorEl]);

  useEffect(() => {
    if (nav.current === null) return;
    let mm = gsap.matchMedia();
    mm.add("(min-width: 600px)", () => {
      const handleNavbarVisibility = () => {
        if (window.scrollY > handleNavbarVisibility.scrollY) {
          // Scrolling down
          gsap.to(navbar, { y: -navbar.clientHeight, opacity: 0 });
          //
          gsap.to(".service-menu-big-screen", { opacity: 0 });
        } else {
          // Scrolling up
          gsap.to(navbar, { y: 0, opacity: 1 });
          gsap.to(".service-menu-big-screen", { opacity: 1 });
        }

        handleNavbarVisibility.scrollY = window.scrollY;
      };
      const navbar = nav.current;

      // Set initial position and visibility of the navbar
      gsap.set(navbar, { y: 0, opacity: 1 });

      // Scroll trigger for hiding and showing the navbar

      // gsap.to(navbar, {
      //   y: -navbar.clientHeight,
      //   opacity: 0,
      //   scrollTrigger: {
      //     trigger: document.body,
      //     start: "top top",
      //     end: "+=100", // Adjust this value for when you want the navbar to start hiding
      //     scrub: 1,
      //     toggleActions: "play reverse play reverse",
      //   },
      // });
      document.addEventListener("scroll", handleNavbarVisibility);
      return () => {
        document.removeEventListener("scroll", handleNavbarVisibility);
      };
    });

    // Function to handle showing/hiding the navbar on scroll

    return () => {
      document.removeEventListener("scroll", () => {});
    };
  }, [nav]);

  // Add this useEffect to handle scroll lock

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function toggleNav() {
    const statusIsOpen = !isOpen;

    setIsOpen(!isOpen);
    const elements = document.querySelectorAll(".nav-text");
    const socialIcons = document.querySelector("#header-social-icon");
    if (statusIsOpen) {
      gsap.to(elements, {
        y: 0,
        opacity: 1,
        duration: 1,
        easing: "power2.in",
      });
      gsap.to(socialIcons, {
        y: 0,
        opacity: 1,
        duration: 1,
        easing: "power2.in",
      });
      gsap.to(".mobile-nav", {
        scaleY: 1,
        easing: "power2.in",
      });
    } else {
      gsap.to(elements, {
        y: 20,
        duration: 1,
        opacity: 0,
        easing: "power2.in",
      });
      gsap.to(socialIcons, {
        y: 20,
        duration: 1,
        opacity: 0,
        easing: "power2.in",
      });

      gsap.to(".mobile-nav", {
        scaleY: 0,
        delay: 1,
        easing: "power2.in",
      });
    }
    const elementsService = document.querySelectorAll(".nav-service-text>div");
    if (statusIsOpen) {
      gsap.set(elementsService, {
        transformOrigin: "bottom",
      });
      gsap.to(elementsService, {
        y: 0,
        // scaleY: 1,
        duration: 1,
      });
      gsap.to(".mobile-nav", {
        scaleY: 1,
      });
    } else {
      gsap.to(elementsService, {
        y: 40,
        duration: 1,
        // scaleY: 0,
      });

      gsap.to(".mobile-nav", {
        scaleY: 0,
        delay: 1,
      });
    }
  }
  function forwardToContact() {
    if (location.pathname === path.contact) return;
    dispatch(onChangeNav(path.contact));
  }
  function forwardToAbout() {
    if (location.pathname === path.about) return;
    dispatch(onChangeNav(path.about));
  }
  function forwardToIT() {
    if (location.pathname === path.it) return;
    dispatch(onChangeNav(path.it));
  }

  function refresh(event) {
    dispatch(onChangeNav("/"));
    const timeline = gsap.timeline({});
    startLoader(timeline);
    dispatch(onChangeNav("/"));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  function forwardToRecruitement() {
    if (location.pathname === path.recruitment) return;
    dispatch(onChangeNav(path.recruitment));
  }
  function forwardToBAE() {
    if (location.pathname === path.bae) return;
    dispatch(onChangeNav(path.bae));
  }
  function forwardToAccounting() {
    if (location.pathname === path.accounting) return;
    dispatch(onChangeNav(path.accounting));
  }
  function handleClose() {
    setAnchorEl(null);
  }
  return (
    <React.Fragment>
      <HeaderContainer className="header" ref={nav}>
        <PrexelIconContainer
          href="/"
          onClick={(event) => refresh(event)}
          id="header-name"
        >
          <PrexelIcon />
        </PrexelIconContainer>
        <FlexContainer jc="flex-end" width="calc(100% - 129px)">
          <HeaderContent>
            <IconContainer
              className="service nav-link"
              id="basic-button"
              open={open}
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <FlexContainer>
                <HeaderTextContainer open={open}>Our Wings</HeaderTextContainer>
                <Span
                  style={{
                    transform: !isServiceMenuClose ? "rotateX(180deg)" : "",
                  }}
                >
                  <DropDown />
                </Span>
              </FlexContainer>
            </IconContainer>
            {/* <Menu>
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
              <MenuItem onClick={handleClose}>Logout</MenuItem>
            </Menu> */}
            <IconContainer onClick={() => forwardToAbout()}>
              <FlexContainer>
                <HeaderTextContainer open={location.pathname === path.about}>
                  About us
                </HeaderTextContainer>
                <Div
                  style={{
                    height: 28.33,
                    width: 2,
                  }}
                />
              </FlexContainer>
              {/* <Div
                style={{
                  background: CommonColor.white,
                  height: 2,
                  width: activeMenuOpen === 1 ? "100%" : 0,
                }}
              /> */}
            </IconContainer>
            <Div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "visible",
              }}
              ref={parent}
            >
              <MagneticHeaderButton
                ref={hoverRef}
                parent={parent}
                onClick={forwardToContact}
                open={location.pathname === path.contact}
              />
            </Div>
          </HeaderContent>
          <HeaderContentMobile>
            <IconContainerNav isvisible={!isOpen} onClick={() => toggleNav()}>
              <NavIconFirstContainer isvisible={!isOpen}>
                <Div />
                <Div />
              </NavIconFirstContainer>
              <NavIconSecond isvisible={!isOpen} />
              <NavIconThirdContainer isvisible={!isOpen}>
                <Div />
                <Div />
              </NavIconThirdContainer>
            </IconContainerNav>
          </HeaderContentMobile>
        </FlexContainer>
      </HeaderContainer>
      <MobileNav className="mobile-nav" isvisible={isOpen}>
        <TitleText className="nav-service-text">Menu</TitleText>
        <MenuText
          className="nav-text"
          onClick={() => setIsServiceMenuClose(!isServiceMenuClose)}
          isvisible={isServiceMenuClose}
        >
          Our Wings
          <Icon icon="simple-line-icons:arrow-up" fontSize={20} />
        </MenuText>
        <ServiceMenuContainerMobile isvisible={isServiceMenuClose}>
          <ServiceMenuText
            className="nav-service-text"
            onClick={() => forwardToRecruitement()}
            isOnPage={location.pathname === path.recruitment}
          >
            <div>Recruitment Services</div>
          </ServiceMenuText>
          <ServiceMenuText
            className="nav-service-text"
            onClick={() => forwardToIT()}
            isOnPage={location.pathname === path.it}
          >
            <div>Digital Solutions</div>
          </ServiceMenuText>
          <ServiceMenuText
            className="nav-service-text"
            onClick={() => forwardToBAE()}
            isOnPage={location.pathname === path.bae}
          >
            <div>Business Assistance & Expansion Services</div>
          </ServiceMenuText>
          <ServiceMenuText
            className="nav-service-text"
            onClick={() => forwardToAccounting()}
            isOnPage={location.pathname === path.accounting}
          >
            <div>Accounting Services</div>
          </ServiceMenuText>
        </ServiceMenuContainerMobile>
        <MenuText
          className="nav-text"
          onClick={() => forwardToAbout()}
          isOnPage={location.pathname === path.about}
        >
          About Us
        </MenuText>
        <MenuText
          className="nav-text"
          onClick={forwardToContact}
          isOnPage={location.pathname === path.contact}
        >
          Contact Us
        </MenuText>
        <SocialIconContainer
          style={{ marginTop: "60px" }}
          id="header-social-icon"
        >
          <SocialIconSingleContainer>
            <a href="https://www.instagram.com/prexel.win">
              <Icon icon="lets-icons:insta" fontSize={width < 600 ? 25 : 17} />
            </a>
          </SocialIconSingleContainer>
          <SocialIconSingleContainer>
            <a href="https://www.facebook.com/prexel.win">
              <Icon icon="ri:meta-line" fontSize={width < 600 ? 25 : 17} />
            </a>
          </SocialIconSingleContainer>
          <SocialIconSingleContainer>
            <a href="https://www.linkedin.com/company/prexel/">
              <Icon icon="ri:linkedin-line" fontSize={width < 600 ? 25 : 17} />
            </a>
          </SocialIconSingleContainer>
          <SocialIconSingleContainer>
            <a href="mailto:prem@prexelllc.com?subject=Subject&body=Body%20goes%20here">
              <Icon icon="ic:outline-email" fontSize={width < 600 ? 25 : 17} />
            </a>
          </SocialIconSingleContainer>
        </SocialIconContainer>
      </MobileNav>
      <ServiceMenuPositioner
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock={true}
        placement="bottom"
        className="service-menu-big-screen"
        id="basic-menu"
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          ".MuiPopper-root": {
            left: "auto !important",
            right: 0,
            width: "200px", // Set
          },
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 2.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            // "&::before": {
            //   content: '""',
            //   display: "block",
            //   position: "absolute",
            //   top: 0,
            //   right: 0,
            //   left: 0,
            //   margin: "auto",
            //   width: 10,
            //   height: 10,
            //   borderRadius: "2.5px",
            //   bgcolor: CommonColor.dropdown_bg,
            //   backdropFilter: "blur(12.5px)",
            //   transform: "translateY(-50%) rotate(45deg)",
            //   zIndex: -1,
            // },
          },
        }}
      >
        {/* {({ TransitionProps }) => (
          <Fade in={open} timeout={350}>
            <div ref={fadeRef} style={{ color: "white" }}> */}
        <MenuHeaderBackground id="header-svg" />
        <ServiceMenuText
          className="nav-service-text"
          isOnPage={location.pathname === path.recruitment}
          onClick={() => forwardToRecruitement()}
        >
          Recruitment Services
          <Icon
            icon="fluent:arrow-right-48-filled"
            // rotate={1}
            fontSize={20}
            fontWeight={500}
          />
        </ServiceMenuText>
        <ServiceMenuText
          className="nav-service-text"
          onClick={() => forwardToIT()}
          isOnPage={location.pathname === path.it}
        >
          Digital Solutions
          <Icon
            icon="fluent:arrow-right-48-filled"
            // rotate={1}
            fontSize={20}
            fontWeight={500}
          />
        </ServiceMenuText>
        <ServiceMenuText
          className="nav-service-text"
          onClick={() => forwardToBAE()}
          isOnPage={location.pathname === path.bae}
        >
          Business Assistance & Expansion Services
          <MenuIconContainer>
            <Icon
              icon="fluent:arrow-right-48-filled"
              // rotate={1}
              fontSize={20}
              fontWeight={500}
              // color={CommonColor.white}
            />
          </MenuIconContainer>
        </ServiceMenuText>
        <ServiceMenuText
          className="nav-service-text"
          onClick={() => forwardToAccounting()}
          isOnPage={location.pathname === path.accounting}
        >
          Accounting Services
          <MenuIconContainer>
            <Icon
              icon="fluent:arrow-right-48-filled"
              // rotate={1}
              fontSize={20}
              fontWeight={500}
              // color={CommonColor.white}
            />
          </MenuIconContainer>
        </ServiceMenuText>
        {/* </div>
          </Fade>
        )} */}
      </ServiceMenuPositioner>
    </React.Fragment>
  );
}
