import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import About from "../component/about/About";
import PageLoader from "../component/commonComponent/Loader";
import Contact from "../component/contact/main";
import useDisableScroll from "../component/Hook/DisableScroll";
import Privacy from "../component/privacy/Privacy";
import Terms from "../component/terms/Terms";
import Accounting from "../screen/accounting/Accounting";
import BAEWing from "../screen/bae/BAE";
import InformationTechnology from "../screen/informationtech/InformationTechnology";
import MainScreen from "../screen/mainScreen/MainScreen";
import RecruitementPage from "../screen/recruitment/Recruitement";
import { startLoader } from "../utils/Functions";
import { path } from "../utils/Path";
gsap.registerPlugin(ScrollTrigger);
function AnimatedRoutes() {
  const dispatch = useDispatch();
  const pathName = useSelector((state) => state.UserStore.location);
  const loader_status = useSelector(
    (state) => state.UserStore.preloader_status
  );
  useDisableScroll(loader_status);

  const navigate = useNavigate();
  useEffect(() => {
    const timeline = gsap.timeline({});
    // dispatch(onTogglePreLoader(true));
    startLoader(timeline);
    setTimeout(() => {
      navigate(pathName);
      window.scrollTo(0, 0);
    }, 900);
  }, [pathName]);
  useEffect(() => {
    if (!loader_status) {
      gsap.to(window, {
        scrollTo: 0,
        duration: 0,
      });
      window.scrollTo(0, 0);
    }
  }, [loader_status]);

  useEffect(() => {
    window.addEventListener("popstate", handleEvent);

    return () => {
      window.removeEventListener("popstate", handleEvent);
    };
  }, []);

  function handleEvent(event) {
    const timeline = gsap.timeline({});
    startLoader(timeline);
    return;
  }

  return (
    <React.Fragment>
      <Routes>
        <Route path={"/"} element={<MainScreen />} />
        <Route path={path.recruitment} element={<RecruitementPage />} />
        <Route path={path.it} element={<InformationTechnology />} />
        <Route path={path.bae} element={<BAEWing />} />
        <Route path={path.contact} element={<Contact />} />
        <Route path={path.about} element={<About />} />
        <Route path={path.privacy_policy} element={<Privacy />} />
        <Route path={path.term_and_condition} element={<Terms />} />
        <Route path={path.accounting} element={<Accounting />} />
      </Routes>
      <PageLoader />
    </React.Fragment>
  );
}

export default AnimatedRoutes;
